$background:#f2f5f9;
$color:#4b5d73;
$border: #e0e8f3;

/*Color variables*/
$primary: var(--primary-bg-color);
$primary-01: var(--primary-01);
$primary-07: var(--primary-07);
$primary-05: var(--primary-05);
$primary-03: var(--primary-03);
$primary-hover: var(--primary-bg-hover);
$primary-border: var(--primary-bg-border);

$secondary:var(--secondary-color);
$secondary-hover : var(--secondary-hover);
$secondary-border:var(--secondary-border);
$secondary-01: var(--secondary-01);
$secondary-05: var(--secondary-05);
$secondary-07: var(--secondary-07);

$primary1:#ff4f57;
$primary2:#fd5043;
$primary3:#e08908;
$primary4:#44b15a;
$primary5:#d8860d;
//  $primary:#ec296b;                                                                        
$primary7:#f55a4f;
$primary8:#ef5249;
$primary10:#e68e0d;

$secondary1:#04378c;
$secondary2:#009688;
$secondary3:#533171;
$secondary4:#543490;
$secondary5:#02776e;
// $secondary:#362ae8;
$secondary7:#511da2;
$secondary8: #2005ad;
$secondary9:#283490;
$secondary10:#4d44ca;

$info:#04b2e5;
$success:#21b632;
$warning:#fb9505;
$danger:#f84242;
$blue:#096cf7;
$yellow:#ffa22b;
$teal:#05e6e6;
$purple:#8b3ce0;
$dark:#343a40;
$indigo:#6574cd;
$white:#fff;
$black:#000;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);                                                                           
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*Dark variables*/
$dark-theme: #121a2e;
$dark-theme2: #1f2940;
$dark-border: $white-1;
$dark-color:#fff;
$dark-shadow: $black-2;

/*Transparent variables*/
$transparent-theme: var(--transparent-color);
$transparent-theme2: $black-2;
$transparent-border: $white-2;
$transparent-color:#fff;
$transparent-shadow: $black-2;
